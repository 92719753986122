<template>  
    <div id="main">
        <PWAPromptComponent />

        <div class="loading-container d-flex flex-column align-items-center justify-content-center" v-if="globalStore.isLoading">
            <img src="@/assets/loading.gif" width="100" alt="Cargando...">
            <p class="text-muted">Espere un momento, por favor...</p>
        </div>

        <div v-if="!globalStore.isLoading">
            <Header v-if="$route.meta.header" />

            <router-view v-slot="{ Component }">
                <main class="h-100 content mb-0 pb-0">
                    <component :is="Component" />
                </main>
            </router-view>
            
            <Footer v-if="$route.meta.header" />
        </div>
    </div>
</template>

<script setup>
    import { useGlobalStore } from './stores/globalStore'
    import Footer from "./components/layouts/Footer.vue";
    import Header from "./components/layouts/Header.vue";
    import PWAPromptComponent from './components/common/PWAPromptComponent.vue';

    const globalStore = useGlobalStore()
</script>

<style scoped>
    .content{
        width: 100% !important;
        height: 100%;
        min-height: 100vh;
        margin: 0;
        padding-bottom: 3rem;
    }
    .loading-container {
        min-height: 100vh;
        align-items: center;
        text-align: center;
    }
</style>
