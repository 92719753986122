import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast } from '@/helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import { useRouter } from 'vue-router'
import server from '../api/server'

export const useGlobalStore = defineStore('globalStore', () => {
    const router = useRouter()
    const auth = ref(null)
    const status = ref('unauthorised')
    const isLoading = ref(false)
    const bearerToken = useLocalStorage('token', '')

    /**
     * Retorna un booleano según el estado de la autenticación.
     * En caso de tener un token en localstorage hará un inicio de sesion
     * automatico.
     * 
     * @return bool
     */
     const checkAuth = async() => {
        setIsLoading(true)
        
        if(status.value === 'unauthorised') {
            if(bearerToken.value == null ||bearerToken.value == '') 
            {
                setIsLoading(false)
                return false
            }

            try 
            {
                const { data } = await server.get('/auth/check')
                if(!data.status) return false
                setAuth(data.user, 'authorised')                
            } 
            catch(err) 
            {
                setIsLoading(false)
                bearerToken.value = null
                return false
            }
        }
        
        setIsLoading(false)
        return true
	}

    const setIsLoading = (value) => {
        isLoading.value = value
    }

    const submitLogin = async({email, password}) => {
        setIsLoading(true)

        try {
            const { data } = await server.post('/auth/login', {email, password})
            const { status, token, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }
            
            bearerToken.value = token.accessToken
            router.push({ name: 'home' })
        } catch(err) {
            swalToast(err, 'error')
            setIsLoading(false)
            return false
        }

        setIsLoading(false)
        return true
    }

    const submitLostPassword = async(email) => {
        try {
            const { data } = await server.post('/auth/password/forget', { email })
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const submitResetPassword = async(formData) => {
        try {
            const { data } = await server.post('/auth/password/reset', formData)
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const logOut = () => {
        setAuth({}, 'unauthorised')
        bearerToken.value = null
        router.push({ name: 'login'} )
    }

    const setAuth = async(authArg, statusArg) => {
		auth.value      = authArg
		status.value    = statusArg
	}
    
    return { 
        auth: computed( () => auth.value ), 
        getToken: computed( () => bearerToken.value ),
        isLoading: computed( () => isLoading.value ), 
        status: computed( () => status.value ),
        checkAuth,
        logOut,
        setIsLoading,
        submitLogin,
        submitLostPassword,
        submitResetPassword,
    }
})
