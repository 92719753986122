<template>
    <article>
        <LoadingCard v-if="isLoading" />
        <div class="card shadow mb-3" v-if="!isLoading">
            <div class="card-body">
                <h5 class="card-title py-3 d-flex justify-content-between align-items-center">
                    <span><i class="bi bi-clipboard"></i> Información básica</span>
                    <span class="fw-bold">{{ getType(form.type) }}</span>
                </h5>

                <div class="row">
                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Operador</label>
                        <input v-model="form.operator" type="text" class="form-control" autocomplete="off" required>
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Código LER</label>
                        <input v-model="form.ler" type="number" class="form-control" autocomplete="off" required placeholder="">
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Fecha de creación</label>
                        <input
                            type="datetime-local"
                            class="form-control"
                            v-model="form.created_at" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class="form-label">Cliente</label>
                        <v-select v-model="form.customer_id" :options="customerStore.users" :reduce="item => item.id" label="code">
                            <template v-slot:option="option">
                                <small>{{ option.code }}</small>
                                <small> - {{ option.name }}</small>
                            </template>
                        </v-select>
                    </div>

                    <div class="col-md-6 col-sm-12 mb-3">
                        <label class="form-label">Local de Descarga</label>
                        <v-select v-model="form.discharge_id" :options="customerStore.users" :reduce="item => item.id" label="code">
                            <template v-slot:option="option">
                                <small>{{ option.code }}</small>
                                <small> - {{ option.name }}</small>
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Transportista</label>
                        <v-select v-model="form.carrier_id" :options="carrierStore.items" :reduce="item => item.id" label="code">
                            <template v-slot:option="option">
                                <small>{{ option.code }}</small>
                                <small> - {{ option.name }}</small>
                            </template>
                        </v-select>
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Vehículo</label>
                        <v-select v-model="form.vehicle_id" :options="vehicleStore.items" :reduce="item => item.id" label="license_plate" />
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Producto</label>
                        <v-select v-model="form.product_id" :clearable="false" :options="productStore.items.filter(el => el.type === form.type)" :reduce="item => item.id" label="sku">
                            <template v-slot:option="option">
                                <small>{{ option.name }}</small>
                                <small> - {{ (option.sku) }}</small>
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="row">
                    

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Peso entrada</label>
                        <div class="input-group flex-nowrap">
                            <input v-model="form.in_weight" type="number" class="form-control">
                            <span class="input-group-text">kg</span>
                        </div>
                    </div>
                    
                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Tara</label>
                        <div class="input-group flex-nowrap">
                            <input v-model="form.weight" type="number" class="form-control">
                            <span class="input-group-text">kg</span>
                        </div>
                        <small class="text-muted">Rellenar solo si no se tiene Vehículo asignado.</small>
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                        <label class="form-label">Peso salida</label>
                        <div class="input-group flex-nowrap">
                            <input :value="Math.abs((form.weight || 0) - (form.in_weight || 0))" disabled class="form-control">
                            <span class="input-group-text">kg</span>
                        </div>
                    </div>
                </div>

                <h5 class="card-title py-3">
                    <i class="bi bi-clipboard"></i> Datos de la Obra |
                    <small>Local de Carga</small>

                </h5>

                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">Código de Obra</label>
                        <v-select v-model="form.work_id" :options="workStore.items" :reduce="item => item.id" label="code" />
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">NIO</label>
                        <input :value="form.work?.data?.nio" class="form-control" disabled readonly>
                    </div>
                    
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">Expediente <small>(licencia)</small></label>
                        <input :value="form.work?.data?.license" class="form-control" disabled readonly>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">Dirección</label>
                        <input :value="form.work?.data?.address" class="form-control" disabled readonly>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">Código postal</label>
                        <input :value="form.work?.data?.cp" class="form-control" disabled readonly>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                        <label class="form-label">Municipio</label>
                        <input :value="form.work?.data?.city" class="form-control" disabled readonly>
                    </div>

                </div>

                <div class="mb-3">
                    <label class="form-label">Notas (observaciones)</label>
                    <textarea class="form-control" v-model="form.observations"></textarea>
                </div>
            </div>

            <button class="btn btn-theme text-white" @click="handleSubmit">Actualizar</button>
        </div>
    </article>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useCarrierStore } from "@/stores/carrier";
import { useProductStore } from "@/stores/product";
import { useWorkStore } from "@/stores/work";
import { useUsersStore } from "@/stores/users";
import { useVehicleStore } from "@/stores/vehicle";
import LoadingCard from "../common/LoadingCard.vue";
import vSelect from "vue-select";
import moment from "moment"

const emits = defineEmits(['on-submit'])
const props = defineProps({
    formData: {
        required: true,
        type: Object
    }
})
const form = ref({})
const isLoading = ref(true)
const productStore = useProductStore()
const customerStore = useUsersStore()
const carrierStore = useCarrierStore()
const workStore = useWorkStore()
const vehicleStore = useVehicleStore()

onBeforeMount( async() => {
    isLoading.value = true
    form.value = {
        ...props.formData,
        created_at: moment(props.formData.created_at).format('YYYY-MM-DD HH:mm')
    }

    if(customerStore.users.length === 0)
        await customerStore.fetch()

    if(productStore.items.length === 0)
        await productStore.fetch()

    if(carrierStore.items.length === 0)
        await carrierStore.fetch()

    if(workStore.items.length === 0)
        await workStore.fetch()

    if(vehicleStore.items.length === 0)
        await vehicleStore.fetch()
    

    isLoading.value = false
})

function handleSubmit() {
    form.value.created_at = moment(form.value.created_at).format('YYYY-MM-DD HH:mm')
    emits('on-submit', form.value)
}

function getType(type) {
    if(type === 'input') {
        return 'Albarán de entrada'
    } else if(type === 'output') {
        return 'Albarán de salida'
    } else {
        return 'Salida de áridos'
    }
}
</script>
