<template>
    <div class="card shadow-lg mb-3">
        <div class="card-body">
            <div class="container text-center">
                <div class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div class="input-group m-2">
                        <label class="input-group-text">Ordenar</label>
                        <button class="btn" 
                            :class="usersStore.orderFilter == 'desc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="usersStore.orderFilter == 'desc'"
                            @click="$emit('on-order', 'desc')">
                            <i class="bi bi-sort-down"></i>
                        </button>
                        <button class="btn"
                            :class="usersStore.orderFilter == 'asc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="usersStore.orderFilter == 'asc'"
                            @click="$emit('on-order', 'asc')">
                            <i class="bi bi-sort-up"></i>
                        </button>
                        <select class="form-select" @change="$emit('on-filter', $event)">
                            <option selected value="0">por defecto</option>
                            <option v-for="option in options" :key="option" :value="option">por {{ option }}</option>
                        </select>
                    </div>

                    <slot></slot>

                    <div class="input-group m-2">
                        <input type="text"
                            class="form-control"
                            placeholder="Escribe para buscar..."
                            @input="onInput" 
                        />
                        <span class="input-group-text">
                            <i class="bi bi-search"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDebounceFn } from "@vueuse/core";
import { useUsersStore } from "../../stores/users";

const usersStore = useUsersStore();
const emits = defineEmits(['on-filter', 'on-order', 'on-search'])

const props = defineProps({
    options: {
        required: true
    }
})

const onInput = useDebounceFn(($e) => {
    emits('on-search', $e)
}, 500)
</script>