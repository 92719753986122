<template>
    <div class="card shadow">
        <div class="card-body">
            <export-component />

            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in store.itemsPaginated" 
                    :key="`each-item-${i}`">

                    <div class="mx-1">
                        <p class="mb-0">cod. {{ item.code }}</p>
                        <p class="small text-muted mb-0">
                            <span>Dirección: {{ `${item.address}, ${item.cp} ${item.city}` }}</span>
                        </p>
                    </div>

                    <div class="ms-auto text-end">
                        <button-icon icon="bi bi-eye" route="works-edit" :params="{id: item.id}" />
                        <button-icon-submit icon="bi bi-x" @on-submit="handleDelete(item.id)"/>
                    </div>
                </div>

                <div v-if="store.itemsPaginated.length <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado resultados</p>
                </div>
            </TransitionGroup>
            
            <pagination-nav-component 
                v-if="store.items.length > 0" 
                :elements="store.items.length" 
                :page="store.page" 
                :itemsPerPage="store.itemsPerPage" 
                @set-page="store.setPage" 
            />
        </div>
    </div>
</template>

<script setup>
import { useWorkStore } from "../../stores/work";
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import PaginationNavComponent from '../common/PaginationNavComponent.vue'
import ExportComponent from "./ExportComponent.vue";
const store = useWorkStore();

const handleDelete = async(id) => {
    await store.destroy(id)
}
</script>