<template>
    <div class="col-md-4">
        <div class="card mb-3 border-left-success shadow py-2 pointer zoom" @click="goTo(route)">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-uppercase mb-1">
                            <span class="fw-bold">{{ label }}</span>
                        </div>
                        <div class="h6 mb-0 font-weight-bold text-gray-800">{{ descr }}</div>
                    </div>
                    <div class="col-auto">
                        <i :class="`bi bi-${icon}`"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useRouter} from 'vue-router';

const router = useRouter()
const props = defineProps({
    label: {
        type: String,
        default: 'Title'
    },
    icon: {
        type: String,
        default: 'bar-chart'
    },
    descr: {
        type: String,
        default: ''
    },
    route: {
        type: String,
        required: true
    }
})

function goTo(args) {
    router.push({name: args})
}
</script>

<style scoped>
    i.bi {
        font-size: 3.5rem;
        color: #ee6300;
    }
</style>
