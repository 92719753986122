<template>
    <page-title-component title="Dashboard" :desc="`Panel de control de ${appTitle}.`" />

    <div class="container-xl mt-n10">
        <div class="row">
            <home-card-component 
                class="w-50"
                route="customers"
                icon="people"
                label="Clientes"
                descr="Ver clientes" />
            
            <home-card-component 
                class="w-50"
                route="products"
                icon="boxes"
                label="Productos"
                descr="Ver productos" />

            <home-card-component 
                class="w-50"
                route="works"
                icon="buildings"
                label="Obras"
                descr="Ver obras" />

            <home-card-component 
                class="w-50"
                route="carriers"
                icon="truck"
                label="Transportistas"
                descr="Ver transportistas" />

            <home-card-component 
                class="w-100"
                route="receipts"
                icon="file-earmark-spreadsheet"
                label="Albaranes"
                descr="Ver albaranes" />
        </div>

        <div class="row">
            <div class="col">
                <generate-receipt />
            </div>
        </div>
    </div>
</template>

<script setup>
import HomeCardComponent from '@/components/home/HomeCardComponent.vue'
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import GenerateReceipt from '@/components/receipts/GenerateReceipt.vue';

const appTitle = process.env.VUE_APP_TITLE
</script>