<template>
    <div class="card shadow mb-3">
		<div class="body-card d-grid">
			<button class="btn rounded-1 justify-content-center btn-outline-theme rounded-0 d-flex gap-1 align-items-center" @click="showModal = true">
				<i class="bi bi-download m-0"></i>
				<p class="m-0">Exportar datos</p>
			</button>

		</div>
    </div>

	<div class="modal fade show" v-if="showModal" style="display:block" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Exportación personalizada</h5>
					<button type="button" class="close btn btn-xl" aria-label="Close" @click="showModal = false">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="text-center d-flex gap-3 justify-content-center align-items-center">
						<button class="btn rounded-1 btn-outline-success rounded-0 d-flex gap-1 align-items-center" @click="handleExport('xls', true)">
							<i class="bi bi-filetype-xls m-0"></i>
							<p class="m-0">Exportar todo (excel)</p>
						</button>

						<button class="btn rounded-1 btn-outline-danger rounded-0 d-flex gap-1 align-items-center" @click="handleExport('pdf', true)">
							<i class="bi bi-filetype-xls m-0"></i>
							<p class="m-0">Exportar todo (PDF)</p>
						</button>
					</div>

					<p class="my-4 text-center w-full fw-bold h6">o exportar por fecha:</p>

					<div class="input-group align-items-center gap-2">
						<label for="start_date">Desde:</label>
						<input type="date" v-model="startDate" id="start_date" class="form-control border-0">
					</div>

					<div class="input-group align-items-center gap-2">
						<label for="end_date">Hasta:</label>
						<input type="date" v-model="endDate" id="end_date" class="form-control border-0">
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success text-white" @click="handleExport('xls')"> <i class="bi bi-download m-0"></i> Exportar (XLS)</button>
					<button type="button" class="btn btn-danger text-white" @click="handleExport('pdf')"> <i class="bi bi-download m-0"></i> Exportar (PDF)</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import server from '@/api/server';
import { onBeforeMount, ref } from 'vue';
import { useWorkStore } from '@/stores/work';
import { swalToast } from '@/helpers/swal';
import { forceFileDownload } from '@/helpers/utils.js'

const showModal = ref()
const downloading = ref(false)
const startDate = ref()
const endDate = ref()
const works = ref()
const store = useWorkStore()
const serverURL = process.env.VUE_APP_SERVER_BASE_URL


onBeforeMount(() => {
	works.value = store.items
})

const submitExportPDF = async() => {
	try {
        const { data } = await server.post('/works/export', {
			start_date: startDate.value, 
			end_date: endDate.value
		}, {
            Accept: 'application/pdf',
        })

        if(!data.status)
            return;

        window.open(`${serverURL}/${data.message}`);
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    } finally {
        downloading.value = false
    }
}

const submitExportXLS = async() => {
	try {
		const { data } = await server.post(`/works/export/xlsx`, {
			start_date: startDate.value, 
			end_date: endDate.value
		}, { responseType: 'arraybuffer' })

		forceFileDownload(data, `works`)
	} catch(err) {
		console.log('[ERR] ExportComponent.vue', err)
		swalToast(err, "error")
	}
}

const handleExport = (type = 'xls', all = false) => {
	downloading.value = true

	if(!all) {
		if(!startDate.value || !endDate.value) {
			return swalToast('Debes seleccionar fechas para una exportación personalizada.', 'error')
		}
	}

	type === 'xls'
		? submitExportXLS()
		: submitExportPDF()
	
	showModal.value = false;
	downloading.value = false;
	startDate.value = '';
	endDate.value = '';
}
</script>

<style scoped>
div.modal {
	display: flex!important;
    align-items: center;
    justify-content: center;
	background-color: rgb(0 0 0 / 58%);
}

div.modal-dialog {
	max-width: 100%;
	min-width: 400px;
}
</style>