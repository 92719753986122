<template>
    <div class="card shadow mb-3">
        <div class="card-body">
            <h5 class="card-title py-3">
                <i class="bi bi-clipboard"></i> Información básica
            </h5>

            <div class="mb-3">
                <label class="form-label">Nombre</label>
                <input v-model="form.name" type="text" class="form-control" autocomplete="off" required placeholder="John Doe...">
            </div>

            <div class="mb-3">
                <label class="form-label">CIF</label>
                <input v-model="form.cif" type="text" class="form-control" autocomplete="off" required placeholder="X-00000000">
            </div>

            <div class="mb-3">
                <label class="form-label">Código</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">T</span>
                    <input v-model="form.code" type="text" class="form-control" autocomplete="off" required placeholder="0000">
                </div>

            </div>

            <div class="mb-3">
                <label class="form-label">Dirección</label>
                <input v-model="form.address" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="mb-3">
                <label class="form-label">Descripción</label>
                <textarea class="form-control" v-model="form.description"></textarea>
            </div>

            <div class="mb-3">
                <label class="form-label">Cliente</label>
                <v-select v-model="form.customer_id" :options="customers" :clearable="false" :reduce="item => item.id" label="name">
                    <template v-slot:option="option">
                        <small>{{ option.name }}</small>
                        <small> - #{{ (option.code) }}</small>
                    </template>
                </v-select>
            </div>
    
            <div class="my-3">
                <label class="form-label">Notas (observaciones)</label>
                <textarea class="form-control" v-model="form.observations"></textarea>
            </div>

            <div class="text-end">
                <button-submit 
                    icon="bi-pencil" 
                    label="Actualizar" 
                    @on-submit="handleSubmit" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";
import { useUsersStore } from "@/stores/users";
import vSelect from "vue-select";

import "vue-select/dist/vue-select.css";

const emits = defineEmits(['on-submit'])
const customerStore = useUsersStore()
const customers = ref([])
const props = defineProps({
    formData: {
        required: true,
        type: Object
    }
})
const form = ref({})

onBeforeMount( async() => {
    form.value = {...props.formData}
    await customerStore.fetch()

    customers.value = [...customerStore.users]
})

function handleSubmit() {
    emits('on-submit', form.value)
}
</script>
