<template>
    <div class="card shadow mb-3">
        <div class="card-body">
            <div class="input-group bg-light rounded p-1">
                <transition name="list">
                    <span class="input-group-text bg-light border-0" :key="`loading-${loading}`">
                        <i v-if="!loading" class="bi bi-search"></i>
                        <div v-else class="spinner-border spinner-border-sm text-theme" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                    </span>
                </transition>

                <input type="text" v-model="keyword" class="form-control border-0 bg-light" placeholder="Buscar matrícula..." @input="onInput">

                <button v-if="keyword.length > 0" class="btn" type="button" @click="clearForm()">
                    <i class="bi bi-x"></i>
                </button>
            </div>
        </div>
    </div>

    <loading-card v-if="loading" />
    
    <div class="row" v-if="form && !loading">
        <div class="col-7">
            <div class="card shadow mb-3">
                <div class="card-body">
                    <h5 class="card-title d-flex gap-2 justify-content-between align-items-center py-3 mb-312">
                        <span class="w-75"><i class="bi bi-clipboard"></i> Nuevo albarán</span>

                        <select v-model="form.type" class="form-select" @change="form.product_id = null">
                            <option value="input">Entrada de residuos</option>
                            <option value="output">Salida de residuos</option>
                            <option value="output_arids">Salida de áridos</option>
                        </select>

                        <input
                            type="datetime-local"
                            class="form-control"
                            v-model="form.created_at" />
                    </h5>
                    
                    <div class="row">
                        <div class="col mb-3">
                            <label class="form-label">Peso entrada</label>
                            <div class="input-group flex-nowrap">
                                <input v-model="form.in_weight" type="number" class="form-control">
                                <span class="input-group-text">kg</span>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <label class="form-label">Tara</label>
                            <div class="input-group flex-nowrap">
                                <input v-model="form.weight" type="number" class="form-control">
                                <span class="input-group-text">kg</span>
                            </div>
                        </div>

                        <div class="col mb-3">
                            <label class="form-label">Peso salida</label>
                            <div class="input-group flex-nowrap">
                                <input :value="Math.abs((form.weight || 0) - (form.in_weight || 0))" disabled class="form-control">
                                <span class="input-group-text">kg</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col mb-3" v-if="!form.customer">
                            <label class="form-label">Cliente</label>
                            <v-select v-model="form.customer_id" :options="customers" :reduce="item => item.id" label="name" placeholder="Selecciona una opción">
                                <template v-slot:option="option">
                                    <small>{{ option.code }}</small>
                                    <small> - {{ option.name }}</small>
                                </template>
                            </v-select>
                        </div>

                       
                        <div class="col mb-3" v-if="form.carrier">
                            <label class="form-label">Transportista</label>
                            <input :value="form.carrier.data.name" type="text" class="form-control" disabled readonly>
                        </div>

                        <div class="col mb-3" v-if="products.length > 0">
                            <label class="form-label">Producto</label>
                            <v-select v-model="form.product_id" :clearable="false" :options="products.filter(el => el.type === form.type)" :reduce="item => item.id" placeholder="Selecciona una opción" label="sku">
                                <template v-slot:option="option">
                                    <small>{{ (option.sku) }} - </small>
                                    <small>{{ option.name }}</small>
                                </template>
                            </v-select>
                        </div>
                    </div>

                    <div class="col mb-3">
                        <label class="form-label">Operador de transporte</label>
                        <input v-model="form.operator" type="text" class="form-control">
                    </div>


                    <div class="col mb-3">
                        <label class="form-label">Local de carga (obra)</label>
                        <v-select v-model="form.work_id" :options="works" :reduce="item => item.id" label="code" placeholder="Selecciona una opción">
                            <template v-slot:option="option">
                                <small>{{ option.code }}</small>
                                <small> - {{ option.address }}</small>
                            </template>
                        </v-select>
                    </div>

                    <div class="col mb-3">
                        <label class="form-label">Local de descarga</label>
                        <v-select v-model="form.discharge_id" :options="customers" :reduce="item => item.id" label="name" placeholder="Selecciona una opción">
                            <template v-slot:option="option">
                                <small>{{ option.code }}</small>
                                <small> - {{ option.name }}</small>
                            </template>
                        </v-select>
                    </div>

                    
                    <div class="mb-3">
                        <label class="form-label">Notas (observaciones)</label>
                        <textarea class="form-control" v-model="form.observations"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="card shadow mb-3">
                <div class="card-body">
                    <h5 class="card-title d-flex justify-content-between align-items-center py-3 mb-3">
                        <span class="fw-bold"><i class="bi bi-info-circle"></i> Resumen</span>
                        <span>{{ dateFormatted(new Date()) }}</span>
                    </h5>

                    <table class="table">
                        <tr>
                            <th class="fw-bold">Peso entrada</th>
                            <td class="text-end">{{ form.in_weight || 0 }} kg</td>
                        </tr>
                        <tr>
                            <th class="fw-bold">Peso neto</th>
                            <td class="text-end">{{ Math.abs((form.weight || 0) - (form.in_weight || 0)) }} kg</td>
                        </tr>
                        <tr>
                            <th class="fw-bold">Precio/kg ({{ getSelectedProduct ? getSelectedProduct.sku : 'No seleccionado' }})</th>
                            <td class="text-end">{{ getSelectedProduct ? getSelectedProduct.price : 0.00 }} €</td>
                        </tr>
                        <tr> 
                            <th colspan="2"><hr class="my-2"></th>
                        </tr>
                        <tr>
                            <th class="fw-bold">Importe</th>
                            <td class="text-end">{{ currencyFormatted(getImport) }}</td>
                        </tr>
                        <tr>
                            <th class="fw-bold">21% IVA</th>
                            <td class="text-end">{{ currencyFormatted(getIVA) }}</td>
                        </tr>
                        <tr> 
                            <th colspan="2"><hr class="my-2"></th>
                        </tr>
                        <tr>
                            <th class="fw-bold">Total</th>
                            <td class="text-end">{{ currencyFormatted(getTotal) }}</td>
                        </tr>
                    </table>

                    <div class="d-flex gap-1 justify-content-between">
                        <div class="d-grid w-100">
                            <button class="btn btn-outline-theme" @click="clearForm">
                                Cancelar
                            </button>
                        </div>

                        <div class="d-grid w-100">
                            <button-submit 
                                icon="bi-plus-square-dotted" 
                                label="Generar" 
                                @on-submit="handleForm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { currencyFormatted } from "@/helpers/currency";
import { dateFormatted } from "@/helpers/moment";
import { onBeforeMount, ref, computed } from "vue";
import { swalToast } from "@/helpers/swal";
import { useCarrierStore } from "@/stores/carrier";
import { useDebounceFn } from "@vueuse/core";
import { useProductStore } from "@/stores/product";
import { useReceiptStore } from "@/stores/receipt";
import { useUsersStore } from "@/stores/users";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";
import LoadingCard from "../common/LoadingCard.vue";
import server from '@/api/server'
import { useWorkStore } from "@/stores/work";
import vSelect from "vue-select";


import "vue-select/dist/vue-select.css";
import moment from "moment";

const loading = ref(false)
const form = ref(null)
const newClient = ref(false)
const keyword = ref('')
const products = ref([])
const works = ref([])
const customers = ref([])

const productStore = useProductStore()
const customerStore = useUsersStore()
const carrierStore = useCarrierStore()
const workStore = useWorkStore()
const receiptStore = useReceiptStore()

onBeforeMount( async() => {
    await productStore.fetch()
    await customerStore.fetch()
    await carrierStore.fetch()
    await workStore.fetch()

    products.value = [...productStore.items]
    works.value = [...workStore.items]
    customers.value = [...customerStore.users]
})

const handleForm = async () => {

    const productSelected = products.value.find(el => el.id === form.value.product_id)
    const response = ref()

    if(productSelected) {
        form.value.price_kg = productSelected.price
        form.value.total = getTotal.value
    }

    form.value.license_plate = keyword.value
    form.value.created_at = moment(form.value.created_at).format('YYYY-MM-DD HH:mm')

    if(newClient.value) {
        response.value = await receiptStore.submitNewCustomer(form.value) //TODO
    } else {
        response.value = await receiptStore.submitForm(form.value)
    }

    if(response.value)
        clearForm()
}

const onInput = useDebounceFn( async() => {
    try {
        newClient.value = false
        form.value = null
        loading.value = true
        const today = moment().format('YYYY-MM-DD HH:mm')
        const { data } = await server.get(`/vehicles/license/${keyword.value}?include=customer,carrier,vehicle`)

        if(data.status === 200) {
            form.value = data.results.data
            form.value.vehicle_id = data.results.data.id
            form.value.work_id = null
            form.value.product_id = null
            form.value.id = 'new'
            form.value.type = 'input'
            form.value.weight = data.results.data.weight
            form.value.created_at = today
        }
    } catch(err) {
        const today = moment().format('YYYY-MM-DD HH:mm')

        let msg = err.message

        if(err.response.status === 404)
            msg = 'La matrícula de vehículo ingresada no está registrada en nuestro sistema. Asegúrate de verificar y completar la información del cliente más adelante para garantizar la precisión de la transacción.'

        newClient.value = true
        
        form.value = {   
            id: 'new',
            type: 'input',
            customer_id: null,
            discharge_id: null,
            work_id: null,
            product_id: null,
            weight: 0,
            created_at: today
        }

        swalToast(msg, 'error', 8000)
    } finally {
        loading.value = false
    }
}, 1200)

const clearForm = () => {
    form.value = null
    keyword.value = ''
}

const getSelectedProduct = computed(() => products.value.find(el => el.id === form.value.product_id))
const getImport = computed(() => ((getSelectedProduct.value ? getSelectedProduct.value.price : 0.00) * Math.abs(form.value.weight - form.value.in_weight || 0)) )
const getIVA = computed(() => getImport.value * 0.21)
const getTotal = computed( () => getImport.value + getIVA.value)
</script>
