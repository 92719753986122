<template>
    <page-title-component 
        title="Albaranes" 
        icon="file-earmark-spreadsheet" 
        desc="Listado de albaranes para consultar sus datos." 
    />

    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />

        <transition name="fade">
            <div v-if="!store.isLoading">
                <filters-component 
                    :options="filterOptions"
                    @on-order="store.sortDirection" 
                    @on-filter="store.filter($event)"
                    @on-search="store.searchInput = $event.target.value">
                    <div class="w-100">
                        <select class="form-select" v-model="store.searchInput">
                            <option value="">Mostrar todos</option>
                            <option value="input">Entrada de residuos</option>
                            <option value="output">Salida de residuos</option>
                            <option value="output_arids">Salida de áridos</option>
                        </select>
                    </div>
                </filters-component>
                    

                <table-component />
            </div>
        </transition>
    </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useReceiptStore } from "@/stores/receipt";
import FiltersComponent from '@/components/common/FiltersComponent.vue'
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import TableComponent from '@/components/receipts/TableComponent.vue'

const store = useReceiptStore();
const filterOptions = ['type', 'operator', 'in_weight', 'out_weight', 'created_at']

onBeforeMount( async() => {
    await store.fetch()
})
</script>