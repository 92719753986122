<template>
    <div class="card mb-3 shadow">
        <div class="card-body">
            <h5 class="card-title mb-3 py-3">
                <i class="bi bi-card-checklist"></i> Albaranes 
            </h5>

            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in receipts" 
                    :key="`each-item-${i}`">

                    <div class="mx-1">
                        <p class="mb-1"><i class="bi bi-calendar-event"></i> {{ dateFormatted(item.created_at) }}</p>
                        <p class="small text-muted mb-0">
                            <span class="me-2 border px-1 rounded">Nº {{ item.id }}</span>
                            <span class="border px-1 rounded"> {{ item.in_weight }}kg <i class="bi bi-box-arrow-in-right"></i> {{ item.out_weight }}kg </span>
                        </p>
                    </div>

                    <div class="ms-auto d-flex text-end">
                        <button-icon icon="bi bi-eye" route="receipts-edit" :params="{id: item.id}" />
                        <button-icon-submit :disabled="downloading" icon="bi bi-download" @on-submit="download(item.id)" />
                        <button-icon-submit icon="bi bi-x" @on-submit="destroyItem(item.id)" />
                    </div>
                </div>

                <div v-if="receipts <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado albaranes</p>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import { dateFormatted } from "@/helpers/moment";
import { onBeforeMount, ref } from "vue";
import { useReceiptStore } from "@/stores/receipt";
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import server from "@/api/server";
import { swalToast } from "@/helpers/swal";

const props = defineProps({
    items: {
        required: true,
        type: Array
    }
})
const store = useReceiptStore()
const receipts = ref([])
const downloading = ref(false)
const serverURL = process.env.VUE_APP_SERVER_BASE_URL

onBeforeMount( async() => {
    receipts.value = [...props.items]
})

async function destroyItem(id) {
    const resp = await store.destroy(id)

    if(resp) {
        const idx = receipts.value.findIndex(el => el.id === id)
        receipts.value.splice(idx, 1)
    }
}

const download = async(id) => {
    try {
        downloading.value = true
        const { data } = await server.get(`/receipts/download/${id}`, {
            Accept: 'application/pdf',
        })
        if(!data.status)
            return;

        window.open(`${serverURL}/${data.message}`);
        downloading.value = false
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    }
}
</script>

<style scoped>
.card-body {
    min-height: 400px;
    overflow-y: scroll;
}
</style>