<template>
    <div class="card shadow mb-3">
		<div class="body-card d-grid">
			<button class="btn rounded-1 justify-content-center btn-outline-theme rounded-0 d-flex gap-1 align-items-center" @click="showModal = true">
				<i class="bi bi-download m-0"></i>
				<p class="m-0">Exportar datos</p>
			</button>

		</div>
    </div>

	<div class="modal fade show" v-if="showModal" style="display:block" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Exportación personalizada</h5>
					<button type="button" class="close btn btn-xl" aria-label="Close" @click="showModal = false">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="mb-2">
						<label class="mb-1" for="type">Tipos de albaranes a exportar:</label>
						<select v-model="receiptType" id="type" class="form-select">
							<option value="">- Selecciona una opción -</option>
							<option value="input">Albaranes entrada de residuos</option>
							<option value="output">Albaranes salida de residuos</option>
							<option value="output_arids">Albaranes salida de áridos</option>
						</select>
					</div>


					<section class="border border-2 p-3 border-radius">
						<p class="mb-2 fw-bold h6">Exportar por fecha:</p>

						<div class="input-group align-items-center gap-2">
							<label for="start_date">Desde:</label>
							<input type="date" v-model="startDate" id="start_date" class="form-control border-0">
						</div>

						<div class="input-group align-items-center gap-2 mb-2">
							<label for="end_date">Hasta:</label>
							<input type="date" v-model="endDate" id="end_date" class="form-control border-0">
						</div>

						<div class="text-center d-flex gap-3 justify-content-center align-items-center ">
							<button :disabled="!startDate || !endDate ? true : false" class="btn rounded-1 w-100 btn-outline-success rounded-0 d-flex gap-1 align-items-center" @click="handleExport('xls')">
								<i class="bi bi-filetype-xls m-0"></i>
								<p class="m-0">Exportar (excel)</p>
							</button>

							<button :disabled="!startDate || !endDate ? true : false" class="btn rounded-1 w-100 btn-outline-danger rounded-0 d-flex gap-1 align-items-center" @click="handleExport('pdf')">
								<i class="bi bi-filetype-pdf m-0"></i>
								<p class="m-0">Exportar (PDF)</p>
							</button>
						</div>
					</section>
				</div>
				<div class="modal-footer d-block">
					<div class="text-center d-flex gap-3 justify-content-center align-items-center mb-3">
						<button class="btn rounded-1 btn-outline-success rounded-0 d-flex gap-1 align-items-center" @click="handleExport('xls', true)">
							<i class="bi bi-filetype-xls m-0"></i>
							<p class="m-0">Exportar todo (excel)</p>
						</button>

						<button class="btn rounded-1 btn-outline-danger rounded-0 d-flex gap-1 align-items-center" @click="handleExport('pdf', true)">
							<i class="bi bi-filetype-pdf m-0"></i>
							<p class="m-0">Exportar todo (PDF)</p>
						</button>
					</div>
					<div class="alert bg-warning">
						<span class="mb-0">Los albaranes que no estén completos<br/> tendrán datos en blanco en la exportación.</span>	
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import server from '@/api/server';
import { onBeforeMount, ref } from 'vue';
import { useReceiptStore } from "../../stores/receipt";
import { swalToast } from '@/helpers/swal';
import { dateFormatted } from '@/helpers/moment';
import { forceFileDownload } from '@/helpers/utils.js'

const showModal = ref()
const downloading = ref(false)
const startDate = ref()
const endDate = ref()
const receipts = ref()
const store = useReceiptStore()
const receiptType = ref('')
const serverURL = process.env.VUE_APP_SERVER_BASE_URL


onBeforeMount(() => {
	receipts.value = store.items
})

const submitExportPDF = async() => {
	try {
        const { data } = await server.post('/receipts/export', {
			start_date: startDate.value, 
			end_date: endDate.value,
			type: receiptType.value
		}, {
            Accept: 'application/pdf',
        })

        if(!data.status)
            return;

        window.open(`${serverURL}/${data.message}`);
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    } finally {
        downloading.value = false
    }
}

const submitExportXLS = async() => {
    try {
        const { data } = await server.post(`/receipts/export/xlsx`, {
					start_date: startDate.value, 
					end_date: endDate.value,
					type: receiptType.value
				}, { responseType: 'arraybuffer' })

        forceFileDownload(data, `albaranes`)
    } catch(err) {
        console.log('[ERR] ExportComponent.vue', err)
        swalToast(err, "error")
    }
}

const getQuantity = (in_weight, weight) => {
	if(!in_weight || !weight) return ','
	return `${in_weight - weight},`
}

const handleExport = (type = 'xls', all = false) => {
	if(receiptType.value === '') {
		swalToast('Debes seleccionar un tipo de albaranes: entrada, salida de residuos...', 'error')
		return;
	}

	downloading.value = true

	if(!all) {
		if(!startDate.value || !endDate.value) {
			return swalToast('Debes seleccionar fechas para una exportación personalizada.', 'error')
		}
	}

	type === 'xls'
		? submitExportXLS()
		: submitExportPDF()
	
	showModal.value = false;
	downloading.value = false;
	startDate.value = '';
	endDate.value = '';
}
</script>

<style scoped>
div.modal {
	display: flex!important;
    align-items: center;
    justify-content: center;
	background-color: rgb(0 0 0 / 58%);
}

div.modal-dialog {
	max-width: 100%;
	min-width: 400px;
}
</style>