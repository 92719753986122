import ReceiptsView from '@/views/ReceiptsView'
import HomeView from '@/views/HomeView'
import isAuth from './guards/isAuth'
import ProductsView from '@/views/ProductsView'
import WorksView from '@/views/WorksView'
import CarriersView from '@/views/CarriersView'
import SingleReceiptView from '@/views/details/SingleReceiptView'
import SingleProductView  from '@/views/details/SingleProductView'
import SingleUserView from '@/views/details/SingleUserView'
import SingleCarrierView from '@/views/details/SingleCarrierView'
import SingleCarrierViewSingle from '@/views/details/SingleCarrierViewSingle'
import SingleVehicleView from '@/views/details/SingleVehicleView'
import SingleWorkView from '@/views/details/SingleWorkView'
import UsersView from '@/views/UsersView'

export default [
    {
        path: '/home',
        name: 'home',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: HomeView
    },
    {
        path: '/customers',
        name: 'customers',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: UsersView
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: ProductsView
    },
    {
        path: '/works',
        name: 'works',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: WorksView
    },
    {
        path: '/carriers',
        name: 'carriers',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: CarriersView
    },
    {
        path: '/receipts',
        name: 'receipts',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: ReceiptsView
    },
    {
        path: '/customers/:id',
        name: 'customers-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleUserView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/carriers/:id',
        name: 'carriers-edit-single',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleCarrierViewSingle,
        props: ( route ) => {
            return {id: route.params.id}
        }
    },
    {
        path: '/customers/:customer/carriers/:id',
        name: 'carriers-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleCarrierView,
        props: ( route ) => {
            return {
                id: route.params.id,
                customer: route.params.customer || null
            }
        }
    },
    {
        path: '/customers/:customer/carriers/:carrier/vehicles/:id',
        name: 'vehicles-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleVehicleView,
        props: ( route ) => {
            return {
                id: route.params.id,
                customer: route.params.customer || null,
                carrier: route.params.carrier || null
            }
        }
    },
    {
        path: '/products/:id',
        name: 'products-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleProductView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/works/:id',
        name: 'works-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleWorkView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/receipts/:id',
        name: 'receipts-edit',
        meta: {
            header: true
        },
        beforeEnter: [isAuth],
        component: SingleReceiptView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
]