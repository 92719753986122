<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-lg">
        <div class="container-fluid justify-content-start">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img src="@/assets/logo.png" height="60" />
            </router-link>
            
            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
                <header-link :items="navbar" @on-change="selectRoute" />

                <ul class="navbar-nav d-flex d-none d-lg-block" v-if="globalStore.status == 'authorised'">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center justify-content-end" 
                            href="#" id="navbarDarkDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            <user-avatar-badge :name="globalStore.auth?.data?.name" />
                        </a>
                        <ul class="dropdown-menu m-0 position-absolute border-0 shadow" aria-labelledby="navbarDarkDropdownMenuLink" style="left:-120px">
                            <li class="pointer">
                                <a class="dropdown-item fw-light" target="_blank" href="#">
                                  <i class="bi bi-info-square"></i> Guía del usuario
                                </a>
                            </li>
                            <li class="pointer">
                                <a class="dropdown-item fw-light" @click="globalStore.logOut">
                                  <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="d-flex ms-auto account-container">
                <ul class="navbar-nav d-block d-lg-none me-3" v-if="globalStore.status == 'authorised'">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center justify-content-end" 
                            href="#" id="navbarDarkDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            <user-avatar-badge :name="globalStore.auth?.data?.name" />
                        </a>
                        <ul class="dropdown-menu m-0 position-absolute border-0 shadow" aria-labelledby="navbarDarkDropdownMenuLink" style="left:-120px">
                            <li class="pointer">
                                <a class="dropdown-item fw-light" target="_blank" href="#">
                                  <i class="bi bi-info-square"></i> Guía del usuario
                                </a>
                            </li>
                            <li class="pointer">
                                <a class="dropdown-item fw-light" @click="globalStore.logOut">
                                  <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <router-link class="btn btn-outline-theme btn-sm mx-2" v-else :to="{name: 'login'}">Iniciar sesión</router-link>

                <button class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
    </nav>
</template>

<script setup>
import {ref} from 'vue'
import {useGlobalStore} from '../../stores/globalStore'
import {useRouter} from 'vue-router'
import HeaderLink from '../common/HeaderLink.vue'
import UserAvatarBadge from '../users/UserAvatarBadge.vue'

const globalStore = useGlobalStore()
const router = useRouter()

const selectRoute = (item) => {
    router.push({ name: item.route })
}

const navbar = ref([
    {
        route: 'home',
        label: 'Inicio',
        icon: 'bi bi-speedometer',
    },
    {
        route: 'customers',
        label: 'Clientes',
        icon: 'bi bi-people',
    },
    {
        route: 'carriers',
        label: 'Transportistas',
        icon: 'bi bi-truck',
    },
    {
        route: 'products',
        label: 'Productos',
        icon: 'bi bi-flower1',
    },
    {
        route: 'works',
        label: 'Obras',
        icon: 'bi bi-buildings',
    },
    {
        route: 'receipts',
        label: 'Albaranes',
        icon: 'bi bi-file-earmark-spreadsheet',
    },
])
</script>

<style scoped>
.account-container {
    position: absolute;
    right: 15px;
    top: 5px;
}
</style>