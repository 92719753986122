import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import server from '../api/server'

export const useReceiptStore = defineStore('receiptStore', () => {
    const endpoint = '/receipts'
    const isLoading = ref(false)
    const items = ref([])
    const itemsPerPage = ref(15)
    const orderFilter = ref('asc')
    const page = ref(1)
    const searchInput = ref('')

    const itemsPaginated = computed( () => {
        let elements = []

        if(searchInput.value.length > 2) {
            elements = items.value.filter( (element) => {
                return (element.customer?.data?.name || '').toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || (element.carrier?.data?.name || '').toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || (element.vehicle?.data?.license_plate || '').toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || (element.product?.data?.type || '').toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || element.id.toString().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            elements = items.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }
        
        return elements
    })

    function setPage(value){
        page.value = value
    }

    function filter(event){
        const field = event.target.value
        items.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg){
        orderFilter.value = arg
        items.value.reverse()
    }

    async function getItem(id){
        return (id != 'new') ? await getById(id) : { id: 'new' }
    }

    async function fetch() {
        try {
            isLoading.value = true
            const { data } = await server.get(endpoint)
            const { status, results } = data

            if(status)
                items.value = results.data.reverse()
        } catch(err) {
            swalToast(err.message, 'error')
        } finally {
            isLoading.value = false
        }
    }

    async function getById(id) {
        try {
            isLoading.value = true
            const { data } = await server.get(`${endpoint}/${id}`)
            const { status, results } = data

            if(status)
                return results.data
        } catch(err) {
            swalToast(err.message, 'error')
            return null
        } finally {
            isLoading.value = false
        }
    }

    async function submitForm(payload) {
        try {
            const action = (payload.id === 'new') ? endpoint : `${endpoint}/update`
            const { data } = await server.post(action, payload)

            swalToast(data.message)
            return data.status;
        } catch(err) {
            swalToast(!err.response.data.errors ? err.message : Object.values(err.response.data.errors)[0], 'error')
            return false
        }
    }

    async function submitNewCustomer(payload) {
        try {
            const { data } = await server.post(`${endpoint}/new-customer`, payload)

            swalToast(data.message)
            return data.status;
        } catch(err) {
            swalToast(!err.response.data.errors ? err.message : Object.values(err.response.data.errors)[0], 'error')
            return false
        }
    }
    
    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este albarán?", "Una vez eliminado no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`${endpoint}/${id}`) 

            const idx = items.value.findIndex(el => el.id === id)
            items.value.splice(idx, 1)
            
            swalToast(data.message)
            return data.status
        } catch(err) {
            swalToast(err.message, 'error')
            return false
        }
    }

    return { 
        isLoading,
        itemsPerPage,
        orderFilter,
        page,
        searchInput,
        items,
        itemsPaginated,
        setPage,
        destroy,
        filter,
        getItem,
        fetch, 
        sortDirection, 
        submitForm,
        submitNewCustomer,
    }
})
