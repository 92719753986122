<template>
    <div class="card shadow mb-3">
        <div class="card-body">
            <h5 class="card-title py-3">
                <i class="bi bi-clipboard"></i> Datos de la Obra
            </h5>

            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Código de Obra</label>
                    <input v-model="form.code" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>

                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Núm. de notificación y identificación (NIO)</label>
                    <input v-model="form.nio" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
                
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Núm. Expediente municipal (licencia)</label>
                    <input v-model="form.license" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Dirección de la Obra</label>
                    <input v-model="form.address" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>

                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Código postal</label>
                    <input v-model="form.cp" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>

                <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <label class="form-label">Municipio de la obra</label>
                    <input v-model="form.city" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
            </div>
            
            <h5 class="card-title py-3 mt-5">
                <i class="bi bi-clipboard"></i> PRODUCTOR (Titular de la licencia municipal)
            </h5>

            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                    <label class="form-label">Nombre/Razón social</label>
                    <input v-model="form.producer_name" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
                
                <div class="col-sm-12 col-md-6 mb-3">
                    <label class="form-label">DNI/CIF</label>
                    <input v-model="form.producer_nif" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
            </div>
            
            <div class="mb-3">
                <label class="form-label">Dirección</label>
                <input v-model="form.producer_address" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <h5 class="card-title py-3 mt-5">
                <i class="bi bi-clipboard"></i> Poseedor de los residuos (Constructor)
            </h5>

            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                    <label class="form-label">Nombre/Razón social</label>
                    <input v-model="form.waste_name" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
                
                <div class="col-sm-12 col-md-6 mb-3">
                    <label class="form-label">DNI/CIF</label>
                    <input v-model="form.waste_nif" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>
            </div>
            
            <div class="mb-3">
                <label class="form-label">Dirección</label>
                <input v-model="form.waste_address" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <h5 class="card-title py-3 mt-5">
                <i class="bi bi-clipboard"></i> Residuos gestionados
            </h5>

            <div class="mb-3">
                <label class="form-label">Código LER</label>
                <input v-model="form.waste_ler" type="number" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <h5 class="card-title py-3 mt-5">
                <i class="bi bi-clipboard"></i> Destinación de los residuos
            </h5>

            <div class="mb-3">
                <select @change="handleWasteDestination" class="form-select">
                    <option value="">OTRO</option>
                    <option value="OPERA">OPERA CATALONIA, S.L.U</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">Código gestor autorizado</label>
                <input v-model="form.waste_destination_code" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="mb-3">
                <label class="form-label">Nombre/Razón social</label>
                <input v-model="form.waste_destination_name" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="mb-3">
                <label class="form-label">Municipio (instalación)</label>
                <input v-model="form.waste_destination_city" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="text-end">
                <button-submit 
                    icon="bi-pencil" 
                    label="Actualizar" 
                    @on-submit="handleSubmit" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";

const emits = defineEmits(['on-submit'])
const props = defineProps({
    formData: {
        required: true,
        type: Object
    }
})
const form = ref({})

onBeforeMount( async() => {
    form.value = {...props.formData}
})

function handleWasteDestination(ev) {
    console.log(ev.target.value)
    if(ev.target.value === 'OPERA') {
        form.value.waste_destination_code = 'E - 1722 . 17'
        form.value.waste_destination_name = 'OPERA CATALONIA, S.L.U / B 55588446'
        form.value.waste_destination_city = '43820 CALAFELL'
    } else {
        form.value.waste_destination_code = ''
        form.value.waste_destination_name = ''
        form.value.waste_destination_city = ''
    }
}

function handleSubmit() {
    emits('on-submit', form.value)
}
</script>
