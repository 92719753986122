<template>
    <page-title-component title="Albaranes" icon="file-earmark-spreadsheet" desc="Listado de albaranes para consultar sus datos." />


    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />

        <div class="row" v-if="!store.isLoading && item">
            <div class="col-md-12">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center mb-1 w-100">

                                <button @click="$router.go(-1)" class="btn rounded-circle btn-sm btn-outline-dark">
                                    <i class="bi bi-arrow-left"></i>
                                </button>

                                <h6 class="m-0 ms-2 w-100" 
                                    v-html="id != 'new' 
                                        ? `Albarán #${item.id}` 
                                        : 'Nuevo alabarán'" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-7 col-sm-12">
                <form-component :form-data="item" @on-submit="submitForm" />
            </div>

            <div class="col-md-5 col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <h5 class="card-title d-flex justify-content-between align-items-center py-3 mb-3">
                            <span class="fw-bold"><i class="bi bi-info-circle"></i> Resumen</span>
                            <span>{{ dateFormatted(item.created_at) }}</span>
                        </h5>

                        <table class="table">
                            <tr>
                                <th class="fw-bold">Peso entrada</th>
                                <td class="text-end">{{ item.in_weight || 0 }} kg</td>
                            </tr>
                            <tr>
                                <th class="fw-bold">Peso neto</th>
                                <td class="text-end">{{ Math.abs((item.weight || 0) - (item.in_weight || 0)) }} kg</td>
                            </tr>
                            <tr>
                                <th class="fw-bold">Precio/kg ({{ item.product?.data?.sku || 'NULL' }})</th>
                                <td class="text-end">{{ (item.product?.data?.price) || 0.00 }} €</td>
                            </tr>
                            <tr> 
                                <th colspan="2"><hr class="my-2"></th>
                            </tr>
                            <tr>
                                <th class="fw-bold">Importe</th>
                                <td class="text-end">{{ currencyFormatted(getImport) }}</td>
                            </tr>
                            <tr>
                                <th class="fw-bold">21% IVA</th>
                                <td class="text-end">{{ currencyFormatted(getIVA) }}</td>
                            </tr>
                            <tr> 
                                <th colspan="2"><hr class="my-2"></th>
                            </tr>
                            <tr>
                                <th class="fw-bold">Total</th>
                                <td class="text-end">{{ currencyFormatted(getTotal) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, computed } from "vue";
import { useReceiptStore } from "@/stores/receipt";
import { useRouter } from 'vue-router'
import FormComponent from "@/components/receipts/FormComponent.vue";
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import { dateFormatted } from "@/helpers/moment";
import { currencyFormatted } from "@/helpers/currency";

const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

const store = useReceiptStore()
const router = useRouter()
const item = ref(null)

const getImport = computed(() => ((item.value.product?.data?.price || 0.00) * Math.abs(item.value.weight - item.value.in_weight || 0)) )
const getIVA = computed(() => getImport.value * 0.21)
const getTotal = computed( () => getImport.value + getIVA.value)

onBeforeMount( async() => {
    item.value = await store.getItem(props.id);
});

const submitForm = async(payload) => {    
    if(await store.submitForm(payload))
        router.push('/receipts')
}
</script>
