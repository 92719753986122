import { useGlobalStore } from '../../stores/globalStore'
import { swalToast } from '../../helpers/swal'

const isAuth = async( to, from, next) => {
    const globalStore = useGlobalStore()
    
    /* Check if the user is authenticated or authorized to access the current route. */
    await globalStore.checkAuth()

    if (globalStore.status !== 'authorised') {
        swalToast("Debes iniciar sesión para acceder a esta sección", 'error')
        return next({ name: 'login' })
    }
    next()
}

export default isAuth