<template>
    <div class="card shadow">
        <div class="card-body">
            <export-component />
            
            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in usersStore.usersPaginated" 
                    :key="`each-item-${i}`">

                    <div class="icon-type bg-light mx-2 rounded small text-muted">
                        <user-avatar-badge :name="item.name" />
                    </div>

                    <div class="mx-1">
                        <p class="mb-0">{{ item.name }}</p>
                        <p class="small text-muted text-start mb-0">cod. {{ item.code }}</p>
                    </div>

                    <div class="ms-auto text-end">
                        <button-icon-submit icon="bi bi-filetype-xls" @on-submit="handleExport(item.id)"/>
                        <button-icon icon="bi bi-eye" route="customers-edit" :params="{id: item.id}" />
                    </div>
                </div>

                <div v-if="usersStore.usersPaginated.length <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado resultados</p>
                </div>
            </TransitionGroup>
            
            <pagination-nav-component 
                v-if="usersStore.users.length > 0" 
                :elements="usersStore.users.length" 
                :page="usersStore.page" 
                :itemsPerPage="usersStore.itemsPerPage" 
                @set-page="usersStore.setPage" 
            />
        </div>
    </div>
</template>

<script setup>
import { forceFileDownload } from '@/helpers/utils.js'
import { useUsersStore } from "../../stores/users";
import server from "@/api/server";
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import PaginationNavComponent from '../common/PaginationNavComponent.vue'
import ExportComponent from "./ExportComponent.vue";
import UserAvatarBadge from "./UserAvatarBadge.vue";
import { swalToast } from '@/helpers/swal';
const usersStore = useUsersStore();

const handleExport = async(id) => {
    try {
        const { data } = await server.get(`/customers/export/${id}`, {responseType: 'arraybuffer'})
        forceFileDownload(data, `cliente-${id}-transportistas`)
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    }
}

</script>