<template>
    <div class="card mb-3 shadow">
        <div class="card-body">
            <h5 class="card-title mb-3 d-flex align-items-center justify-content-between">
                <span class="fw-bold"><i class="bi bi-truck"></i> Transportistas</span>
                <button-icon icon="bi bi-plus" route="carriers-edit" :params="{id: 'new', customer}" />
            </h5>

            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in carriers" 
                    :key="`each-item-${i}`">

                    <div class="mx-1">
                        <p class="mb-0">{{ item.name }}</p>
                        <p class="small text-muted text-start mb-0">{{ item.description.substr(0,35) }}<span v-if="item.description.length > 35">...</span></p>
                    </div>

                    <div class="ms-auto text-end d-flex">
                        <button-icon icon="bi bi-eye" route="carriers-edit" :params="{id: item.id, customer: item.customer_id}" />
                        <button-icon-submit icon="bi bi-x" @on-submit="destroyItem(item.id)" />
                    </div>
                </div>

                <div v-if="carriers <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado transportistas</p>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import { useCarrierStore } from "@/stores/carrier";
import { onBeforeMount, ref } from "vue";

const carriers = ref({})
const store = useCarrierStore()
const props = defineProps({
    items: {
        required: true,
        type: Array
    },
    customer: {
        default: null
    }
})

onBeforeMount( async() => {
    carriers.value = [...props.items]
})

async function destroyItem(id) {
    const resp = await store.destroy(id)

    if(resp) {
        const idx = carriers.value.findIndex(el => el.id === id)
        carriers.value.splice(idx, 1)
    }
}
</script>

<style scoped>
.card-body {
    min-height: 400px;
    overflow-y: scroll;
}
</style>
