<template>
    <div class="card shadow mb-3">
        <div class="card-body">
            <h5 class="card-title py-3">
                <i class="bi bi-clipboard"></i> Información básica
            </h5>

            <div class="mb-3">
                <label class="form-label">Nombre de producto</label>
                <input v-model="form.name" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>
            
            <div class="mb-3">
                <label class="form-label">Código de producto</label>
                <input v-model="form.sku" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="mb-3">
                <label class="form-label">Tipo de producto</label>
                <select class="form-select" v-model="form.type">
                    <option value="input">Entrada de residuos</option>
                    <option value="output">Salida de residuos</option>
                    <option value="output_arids">Salida de áridos</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">Precio por Kg</label>
                <input v-model="form.price" type="number" step=".001" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="mb-3">
                <label class="form-label">Descripción</label>
                <input v-model="form.description" type="text" class="form-control" autocomplete="off" required placeholder="">
            </div>

            <div class="text-end">
                <button-submit 
                    icon="bi-pencil" 
                    label="Actualizar" 
                    @on-submit="handleSubmit" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";

const emits = defineEmits(['on-submit'])
const props = defineProps({
    formData: {
        required: true,
        type: Object
    }
})
const form = ref({})

onBeforeMount( async() => {
    form.value = {...props.formData}
})

function handleSubmit() {
    emits('on-submit', form.value)
}
</script>
