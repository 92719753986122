<template>
    <div class="card shadow">
        <div class="card-body">
            <export-component />

            <TransitionGroup name="list" tag="div">
                <div class="py-2 px-1 border-bottom d-flex align-items-center" 
                    v-for="(item, i) in store.itemsPaginated" 
                    :key="`each-item-${i}`">

                    <div class="mx-1">
                        <p class="mb-0">{{ item.sku != 0 ? item.sku : item.name }}</p>

                        <div class="d-flex type-col gap-2 align-items-center justify-content-center text-center">
                            <span class="mx-auto product-type" :class="item.type === 'input' ? 'bg-theme' : 'bg-secondary'">
                                <i class="bi" :class="item.type === 'input' ? 'bi-arrow-right' : 'bi-arrow-left'"></i>
                            </span>
                            
                            <p v-if="item.sku != 0" class="small text-muted mb-0">
                                <span>{{ item.name }}</span>
                            </p>
                            
                            <p class="small text-muted mb-0">({{ getType(item.type) }})</p>
                        </div>
                    </div>

                   

                    <div class="ms-auto text-end">
                        <button-icon-submit icon="bi bi-filetype-xls" @on-submit="handleExport(item.id)"/>
                        <button-icon icon="bi bi-eye" route="products-edit" :params="{id: item.id}" />
                        <button-icon-submit icon="bi bi-x" @on-submit="handleDelete(item.id)"/>
                    </div>
                </div>

                <div v-if="store.itemsPaginated.length <= 0" class="py-5 text-center text-muted" key="no-results">
                    <i class="bi bi-funnel h2"></i>
                    <p>No se han encontrado resultados</p>
                </div>
            </TransitionGroup>
            
            <pagination-nav-component 
                v-if="store.items.length > 0" 
                :elements="store.items.length" 
                :page="store.page" 
                :itemsPerPage="store.itemsPerPage" 
                @set-page="store.setPage" 
            />
        </div>
    </div>
</template>

<script setup>
import { useProductStore } from "../../stores/product";
import { forceFileDownload } from '@/helpers/utils.js'
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import ButtonIconSubmit from "../common/buttons/ButtonIconSubmit.vue";
import PaginationNavComponent from '../common/PaginationNavComponent.vue'
import ExportComponent from "@/components/products/ExportComponent.vue";
import server from '@/api/server';
import { swalToast } from "@/helpers/swal";

const store = useProductStore();

const getType = (type) => {
    if(type === 'input')
        return 'Entrada de residuos'
    if(type === 'output')
        return 'Salida de residuos'
    if(type === 'output_arids')
        return 'Salida de áridos'
}

const handleDelete = async(id) => {
    await store.destroy(id)
}

const handleExport = async(id) => {
    try {
        const { data } = await server.get(`/products/export/${id}`, {responseType: 'arraybuffer'})
        forceFileDownload(data, `producto-${id}-albaranes`)
    } catch(err) {
        console.log('[ERR] TableComponent.vue', err)
        swalToast(err, "error")
    }
}


</script>

<style scoped>
.product-type {
    height: 24px;
    width: 24px;
    border-radius: 100%;
}

.type-col {
    min-width: 70px;
}
</style>