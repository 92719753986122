<template>
    <div class="card shadow mb-3">
        <div class="card-body">
            <h5 class="card-title py-3">
                <i class="bi bi-clipboard"></i> Información básica
            </h5>

            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Nombre</label>
                    <input v-model="form.name" type="text" class="form-control" autocomplete="off" required placeholder="John Doe...">
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">NIF</label>
                    <input v-model="form.nif" type="text" class="form-control" autocomplete="off" required placeholder="">
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Código</label>
                    <input v-model="form.code" type="text" class="form-control" autocomplete="off" required>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Tipo de cliente</label>
                    <select v-model="form.type" class="form-select" required>
                        <option value="Tipo 1">Tipo 1</option>
                        <option value="Tipo 2">Tipo 2</option>
                        <option value="Tipo 3">Tipo 3</option>
                    </select>
                </div>
            </div>
            
            <h5 class="card-title mt-3 py-3">
                <i class="bi bi-house"></i> Información de contacto 
            </h5>

            <div class="row">
                <div class="col-12 mb-3">
                    <label class="form-label">Dirección</label>
                    <input v-model="form.address" type="text" class="form-control" autocomplete="off" placeholder="Koss Mountain..." required>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Ciudad</label>
                    <input v-model="form.city" type="text" class="form-control" autocomplete="off" placeholder="Barcelona" required>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Código postal</label>
                    <input v-model="form.cp" type="text" class="form-control" autocomplete="off" placeholder="" required>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">Estado/Provincia</label>
                    <input v-model="form.state" type="text" class="form-control" autocomplete="off" placeholder="Lleida" required>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label">País</label>
                    <input v-model="form.country" type="text" class="form-control" autocomplete="off" placeholder="España" required>
                </div>
            </div>

            <div class="my-3">
                <label class="form-label">Notas (observaciones)</label>
                <textarea class="form-control" v-model="form.observations"></textarea>
            </div>

            <div class="text-end">
                <button-submit 
                    icon="bi-pencil" 
                    label="Actualizar" 
                    @on-submit="handleSubmit" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";

const emits = defineEmits(['on-submit'])
const props = defineProps({
    formData: {
        required: true,
        type: Object
    }
})
const form = ref({})

onBeforeMount( async() => {
    form.value = {...props.formData}
})

function handleSubmit() {
    emits('on-submit', form.value)
}
</script>
