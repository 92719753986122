<template>
    <page-title-component title="Productos" icon="file-earmark-spreadsheet" desc="Listado de productos para consultar sus datos." />


    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />

        <div class="row" v-if="!store.isLoading && item">
            <div class="col-md-12">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center mb-1 w-100">

                                <button @click="$router.go(-1)" class="btn rounded-circle btn-sm btn-outline-dark">
                                    <i class="bi bi-arrow-left"></i>
                                </button>

                                <h6 class="m-0 ms-2 w-100" 
                                    v-html="id != 'new' 
                                        ? `Producto #${item.id}` 
                                        : 'Nuevo producto'" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <form-component :form-data="item" @on-submit="submitForm" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useProductStore } from "@/stores/product";
import { useRouter } from 'vue-router'
import FormComponent from "@/components/products/FormComponent.vue";
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'

const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

const store = useProductStore()
const router = useRouter()
const item = ref(null)

onBeforeMount( async() => {
    item.value = await store.getItem(props.id);
});

const submitForm = async(payload) => {    
    if(await store.submitForm(payload))
        router.push('/products')
}
</script>
