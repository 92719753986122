<template>
    <page-title-component title="Obras" icon="buildings" desc="Listado de obras, consulta la información o modifica sus datos." />

    <div class="container-lg mt-n10">
            <loading-card v-if="store.isLoading" />

            <transition name="fade">
                <div v-if="!store.isLoading">
                    <filters-component 
                        :options="filterOptions"
                        @on-order="store.sortDirection" 
                        @on-filter="store.filter($event)"
                        @on-search="store.searchInput = $event.target.value" 
                    />

                    <button-add-new route="works-edit" />
                    <table-results />
                </div>
            </transition>
        </div>
</template>

<script setup>
import { useWorkStore } from "../stores/work";
import ButtonAddNew from '../components/common/ButtonAddNew.vue'
import FiltersComponent from '../components/common/FiltersComponent.vue'
import LoadingCard from '../components/common/LoadingCard.vue'
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableResults from '../components/works/TableResults.vue'
import { onBeforeMount } from "vue";

const store = useWorkStore()
const filterOptions = ['nio', 'license', 'address', 'cp', 'city']

onBeforeMount( async() => {
    await store.fetch()
})
</script>