<template>
    <page-title-component 
        title="Clientes" 
        icon="people" 
        desc="Listado de clientes para consultar sus datos." 
    />

    <div class="container-lg mt-n10">
        <loading-card v-if="usersStore.isLoading" />

        <transition name="fade">
            <div v-if="!usersStore.isLoading">
                <filters-component 
                    :options="filterOptions"
                    @on-order="usersStore.sortDirection" 
                    @on-filter="usersStore.filter($event)"
                    @on-search="usersStore.searchInput = $event.target.value" 
                />

                <button-add-new route="customers-edit" />

                <table-users />
            </div>
        </transition>
    </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useUsersStore } from "../stores/users";
import ButtonAddNew from '../components/common/ButtonAddNew.vue'
import FiltersComponent from '../components/common/FiltersComponent.vue'
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableUsers from '../components/users/TableUsers.vue'

const usersStore = useUsersStore();
const filterOptions = ['name', 'code', 'type', 'created_at']

onBeforeMount( async() => {
    await usersStore.fetch()
})
</script>