<template>
    <page-title-component title="Clientes" icon="people" desc="Listado de clientes para consultar sus datos." />


    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />

        <div class="row" v-if="!store.isLoading && item">
            <div class="col-md-12">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center mb-1 w-100">

                                <router-link :to="{ name: 'customers' }" class="btn rounded-circle btn-sm btn-outline-dark">
                                    <i class="bi bi-arrow-left"></i>
                                </router-link>

                                <h6 class="m-0 ms-2 w-100" 
                                    v-html="id != 'new' 
                                        ? item.name 
                                        : 'Nuevo usuario'" 
                                />

                                <user-avatar-badge 
                                    v-if="id != 'new'" 
                                    style="min-width: 35px" 
                                    :name="item.name" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <form-component :form-data="item" @on-submit="submitForm" />
                <remove-form v-if="id != 'new'" @on-submit="destroy" />
            </div>
            
            <div class="col-md-6 col-sm-12" v-if="id != 'new'">
                <carriers-component :items="item.carriers.data || []" :customer="item.id" />
                <receipts-component :items="item.receipts.data || []" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRouter } from 'vue-router'
import { useUsersStore } from '@/stores/users';
import CarriersComponent from "@/components/users/CarriersComponent.vue";
import FormComponent from "@/components/users/FormComponent.vue";
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import ReceiptsComponent from "@/components/users/ReceiptsComponent.vue";
import RemoveForm from "@/components/common/RemoveForm.vue"
import UserAvatarBadge from '@/components/users/UserAvatarBadge.vue'

const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

const store = useUsersStore()
const router = useRouter()
const item = ref()

onBeforeMount( async() => {
    item.value = await store.getItem(props.id);
});

const submitForm = async(payload) => {    
    if(await store.submitForm(payload))
        router.push({name: 'customers'})
}

const destroy = async() => {
    if(await store.destroy(props.id))
        router.push({name: 'customers'})
}
</script>
