<template>
    <page-title-component title="Transportistas" icon="people" desc="Listado de transportistas para consultar sus datos." />


    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />

        <div class="row" v-if="!store.isLoading && item">
            <div class="col-md-12">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center mb-1 w-100">

                                <button @click="router.go(-1)" class="btn rounded-circle btn-sm btn-outline-dark">
                                    <i class="bi bi-arrow-left"></i>
                                </button>

                                <h6 class="m-0 ms-2 w-100" 
                                    v-html="id != 'new' 
                                        ? item.name 
                                        : 'Nuevo transportista'" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <form-component :form-data="item" @on-submit="submitForm" />
            </div>
            
            <div class="col-md-6 col-sm-12" v-if="id != 'new'">
                <vehicles-component :items="item.vehicles?.data || []" :customer="item.customer_id" :carrier="item.id" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useCarrierStore } from '@/stores/carrier';
import { useRouter } from 'vue-router'
import LoadingCard from "@/components/common/LoadingCard.vue";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import VehiclesComponent from "@/components/users/VehiclesComponent.vue";
import FormComponent from "@/components/carriers/FormComponent.vue";

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    customer: {
        type: String,
        required: true
    },
})

const store = useCarrierStore()
const router = useRouter()
const item = ref(null)

onBeforeMount( async() => {
    item.value = await store.getItem(props.id);

    if(props.customer)
        item.value.customer_id = props.customer
});

const submitForm = async(payload) => {  
    if(!await store.submitForm(payload)  )
        return

    if(props.id == 'new')
        router.go(-1)
    else
        router.push(`/customers/${item.value.customer_id}`)
}
</script>
