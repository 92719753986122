<template>
    <page-title-component title="Productos" icon="boxes" desc="Listado de productos, consulta la información o modifica sus datos." />

    <div class="container-lg mt-n10">
            <loading-card v-if="store.isLoading" />

            <transition name="fade">
                <div v-if="!store.isLoading">
                    <filters-component 
                        :options="filterOptions"
                        @on-order="store.sortDirection" 
                        @on-filter="store.filter($event)"
                        @on-search="store.searchInput = $event.target.value">
                        <div class="w-100">
                            <select class="form-select" v-model="store.searchInput">
                                <option value="">Mostrar todos</option>
                                <option value="input">Entrada de residuos</option>
                                <option value="output">Salida de residuos</option>
                                <option value="output_arids">Salida de áridos</option>
                            </select>
                        </div>
                    </filters-component>

                    <button-add-new route="products-edit" />
                    <table-products />
                </div>
            </transition>
        </div>
</template>

<script setup>
import ButtonAddNew from '../components/common/ButtonAddNew.vue'
import FiltersComponent from '../components/common/FiltersComponent.vue'
import LoadingCard from '../components/common/LoadingCard.vue'
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableProducts from '../components/products/TableProducts.vue'
import { useProductStore } from "../stores/product";
import { onBeforeMount } from "vue";

const store = useProductStore()
const filterOptions = ['sku', 'type', 'created_at']

onBeforeMount( async() => {
    await store.fetch()
})
</script>